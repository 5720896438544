<template>
    <div class="loginBG" >
       <Button type="primary" class="clearBtn" @click="clearBtn()"><Icon type="md-arrow-round-back" />返回列表</Button>
        <div class="titleBox">{{queryListData.title}}</div>
        <div class="poemContent" v-for="item in queryListData.content" :key="item.name"> 
            <div class="poemTitme">{{item.name}}</div>
            <div class="dynasty" v-if="item.dynasty_info">
                <span v-for="item1 in item.dynasty_info" :key="item1.pinyin">{{item1.text}}</span>
                <span style="font-size:20px">·</span>
                <span v-for="item1 in item.author_info" :key="item1.pinyin">{{item1.text}}</span>
            </div>
            <div class="contentBox" :style="{fontSize:value3+'px','line-height':value3*2+'px'}" v-if="tab == 1">
                <span v-for="(item1,index) in item.content" :key="index" >
                        <span v-if="!item1.is_fill" >{{item1.text}}</span>
                        <span v-if="item1.is_fill && queryListData.fill_type == 0" class="lineBox" >
                          <span v-if="showAnswer"  v-for="(item2,index) in item1.text" :key="index" :style="{'display': 'inline-block','width':value3+'px'}">{{showAnswer?item2:''}}</span>
                          <span v-if="!showAnswer" v-for="(item2,index) in item1.text" :key="index" :style="{'display': 'inline-block','width':value3+'px','visibility': 'hidden;'}">{{showAnswer?item2:''}}</span>
                        </span>
                         <span v-if="item1.is_fill && queryListData.fill_type == 1" >
                          <span>(<span v-for="item2 in item1.text" :key="item2" :style="{'width':value3+'px','display': 'inline-block','color':'red'}">{{showAnswer? item2:''}}</span>)</span>
                          </span>
                        <span v-if="item1.is_fill && queryListData.fill_type == 2" >
                            <span v-for="item2 in item1.text" class="lienTian" :style="{'width':value3*1.5+'px',height:value3*1.5+'px','line-height':value3*1.5+'px'}" :key="item2"><span style="display:inline-block">{{showAnswer? item2:''}}</span></span>
                        </span>
                        <span v-if="item1.is_fill && queryListData.fill_type == 3" style="display: inline-block;text-align: center;">
                            <span  v-for="(item2,index) in item1.text" :key="index" :style="{'display':'inline-block','height':value3+value3*1.5+'px','margin-right':'-2px','width':value3*1.5+'px'}">
                                <span class="pinItem" :style="{'height':value3+'px'}">{{item1.pinSp[index]}}</span>
                                <span class="lienTian" :style="{'display':'block','width':value3*1.5+'px',height:value3*1.5+'px','line-height':value3*1.5+'px'}"> <span style="display: inline-block;">{{showAnswer? item2:''}}</span></span>
                            </span>
                        </span>
                </span>
            </div>
            <div class="contentBox" :style="{fontSize:value3+'px','line-height':value3*2+'px','text-align':'center'}" v-if="tab == 2">
                <span v-for="(item1,index) in item.content" :key="index" >
                        <span v-if="!item1.is_fill" v-for="(item2,index) in item1.text" :key='index'>
                          <span v-if="item2!='\n'">{{item2}}</span>
                          <br v-if="item2=='\n'" />
                          </span>
                        <span v-if="item1.is_fill && queryListData.fill_type == 0" class="lineBox" >
                          <span v-if="showAnswer"  v-for="(item2,index) in item1.text" :key="index" :style="{'display': 'inline-block','height':'45px;','width':value3+'px'}">{{showAnswer?item2:''}}</span>
                          <span v-if="!showAnswer" v-for="(item2,index) in item1.text" :key="index" :style="{'display': 'inline-block','height':'45px','width':value3+'px','visibility': 'hidden;'}">{{showAnswer?item2:''}}</span>
                        </span>
                         <span v-if="item1.is_fill && queryListData.fill_type == 1" >
                          <span>(<span v-for="(item2,index) in item1.text" :key="index" :style="{'width':value3+'px','display': 'inline-block','color':'red'}">{{showAnswer? item2:''}}</span>)</span>
                          </span>
                        <span v-if="item1.is_fill && queryListData.fill_type == 2" >
                            <span v-for="item2 in item1.text" class="lienTian" :style="{'width':value3*1.5+'px',height:value3*1.5+'px','line-height':value3*1.5+'px'}" :key="item2"><span style="display:inline-block">{{showAnswer? item2:''}}</span></span>
                        </span>
                        <span v-if="item1.is_fill && queryListData.fill_type == 3" style="display: inline-block;text-align: center;">
                            <span  v-for="(item2,index) in item1.text" :key="index" :style="{'display':'inline-block','height':value3+value3*1.5+'px','margin-right':'-2px','width':value3*1.5+'px'}">
                                <span class="pinItem" :style="{'height':value3+'px'}">{{item1.pinSp[index]}}</span>
                                <span class="lienTian" :style="{'display':'block','width':value3*1.5+'px',height:value3*1.5+'px','line-height':value3*1.5+'px'}"> <span style="display: inline-block;">{{showAnswer? item2:''}}</span></span>
                            </span>
                        </span>
                </span>
            </div>
        </div>
         <div class="bottomBox">
                <div>

                  <span>显示答案：</span>
                  <i-Switch v-model="showAnswer" @on-change="ShowAnsChange" style="margin-top:-5px;margin-left:5px"></i-Switch>

                </div>
                 <div>
                    <span>字号：</span>
                    <Input-number v-model="value3" @on-change="chengSize" :min="20" :max="80" size="large"></Input-number>
                </div>
                
            </div>
    </div>

</template>
  
<script>
import axios from "axios";
import qs from "qs"
export default {
    data() {
      return {
        id:'',
        queryListData:"",
        showAnswer:false,
        value3:25,
        tab:1
      };
    },
    created() {
      console.log( this.$route.query)
      if(window.localStorage.getItem('pomeValue')){
        this.value3 = Number(window.localStorage.getItem('pomeValue'))
      }
        this.id = this.$route.query.id
        this.tab = this.$route.query.tab
        this.queryWordList()
    },
    mounted() {
     
    },
    methods: {
     OptBtn(){
        this.showType = this.showType == 1 ? '2':'1'
     },
     ShowAnsChange(){

     },
     chengSize(e){
       window.localStorage.setItem('pomeValue',e)
     },
  clearBtn(){
    this.$router.push('topicList')
  },
    
    queryWordList(){
        const that = this
        axios({
          headers:{
            'Content-Type':'application/x-www-form-urlencoded',
            'Authorization': window.localStorage.getItem('sessionId'),
          },
          method: 'GET',
          url: '/v2/course/fill/'+this.id,
          data:qs.stringify({
                id:window.localStorage.getItem('sessionId'),
            })  
          })
        .then((res) => {
            if(res.data.code == 0){
                that.queryListData = res.data.data.fill
                let list = res.data.data.fill.content
                list.map(item=>{
                    item.content.map(item1=>{
                        if(item1.pinyin){
                            item1.pinSp = item1.pinyin.split(' ')
                        }else{
                             item1.pinSp = []
                        }
                    })

                })

                that.queryListData.content = list
            }else{

            }
            
            })
        .catch((err) => {
            that.$Message.error(err);
        });
      }
    },
  };
  </script>
  
  <style scoped>
    .loginBG{
        width: 100%;
        height: 100%;
        background: url('https://r.bjwxjs.com/PC_kpy/pc_topic_bg.png') 0 0 / 100% 100% no-repeat;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        overflow: auto;
        padding:60px;
        box-sizing: border-box;
    }
    .poemTitme{
        font-size: 36px;
        text-align: center;
        margin-top: 30px;
    }
  .titleBox{
    font-size: 44px;
    text-align: center;
    font-weight: 600;
    
  }
  .dynasty{
    font-size: 26px;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .contentBox{
    font-size: 30px;
    text-align: justify;
    margin-top: 20px;
    padding-bottom: 100px;
    
  }
  .lineBox{
    display: inline-block;
    border-bottom:2px solid  red;
    min-width:50px;
    color: red;
    /* height: 45px; */
    text-align: center;
    /* line-height: 45px; */
  }
  .lienTian{
    width: 50px;
    height: 50px;
    line-height: 50px;
    color: red;
    display: inline-block;
    text-align: center;
    margin-right: -2px;
    background:  url('https://r.bjwxjs.com/kpyImages/tianzigegreen.png') 0 0/ 100% 100% no-repeat !important;;
  }
  .KuoBox{
    display: inline-block;


  }
  .pinItem{
    height: 25px;
    text-align: center !important;
    width: 50px;
     font-family: "pinyin";
     font-size: 20px;
     display: block;
     line-height: 25px;
  }
  .bottomBox{
        width: 800px;
        height: 60px;
        background: #FCFDFF;
        box-shadow: 0px 2px 5px 0px #95C9FF;
        border-radius: 15px;
        margin-left: 10px;
        display: flex;
        font-size: 24px;
        font-weight: 500;
        color: #606266;
        text-shadow: 0px 2px 10px #95C9FF;
        line-height: 60px;
        margin:20px auto;
        position: fixed;
        bottom: 30px;
        left: 50%;
        transform: translate(-50%,0);
   }
   .bottomBox > div{
        width: 320px;
        text-align: center;
   }
   .bottomBox > div{
        width: 320px;
        text-align: center;
   }
   .ivu-input-number-large{
        font-size: 20px;
        margin: 0 15px;
        text-align: center;
   }
  /deep/ .ivu-input-number-input{
        text-align: center;
   }
   .clearBtn{
    position: fixed;
    top: 40px;
    right: 50px;
   }
       
  </style>
  